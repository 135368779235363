<template>
  <div class="page">
    <div class="content_us_left_area"></div>
    <div class="content_us_right_area"></div>
    <div class="page_frame">
      <div class="form_select">您好，<br />请留下您的联系方式</div>
      <div class="qrcode1">企业公众号</div>
      <div class="qrcode2">企业微信号</div>
      <div class="red_line"></div>
      <div class="content_us_table">
        <select
          :class="[alert === 1 ? 'input_frame1 alert' : 'input_frame1']"
          v-model="product"
        >
          <option value="" disabled selected hidden>
            请选择你感兴趣的产品名称（必填）
          </option>
          <option v-for="(item, index) in list" :key="index" :value="item.id">
            {{ item.model }}
          </option>
        </select>
        <input
          :class="[alert === 2 ? 'input_frame2 alert' : 'input_frame2']"
          type="text"
          placeholder="请输入姓名（必填）"
          v-model="name"
        />
        <input
          :class="[alert === 3 ? 'input_frame2 alert' : 'input_frame2']"
          type="text"
          placeholder="请输入公司（必填）"
          v-model="company"
        />
        <input
          :class="[alert === 4 ? 'input_frame2 alert' : 'input_frame2']"
          type="tel"
          maxlength="11"
          placeholder="请输入电话（必填）"
          v-model="tel"
        />
        <input
          :class="[alert === 5 ? 'input_frame2 alert' : 'input_frame2']"
          type="email"
          placeholder="请输入邮箱（选填）"
          v-model="email"
        />
        <textarea
          class="input_frame3"
          placeholder="在线留言（选填）"
          v-model="remark"
        ></textarea>
        <div class="submit_notes">
          *通过提交，所提供的数据将根据隐私政策执行您的要求
        </div>
        <div class="submit_btn" @click="submit_form()">提交留言</div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { useRoute } from "vue-router";
import Footer from "../components/Footer";
import { GET_COMMENTS, GET_PRODUCTS } from "../http/api";
import { createToaster } from "@meforma/vue-toaster";
import { isMobile } from "@basitcodeenv/vue3-device-detect";

const toaster = createToaster({
  position: "top",
  duration: 2500,
});

export default {
  data() {
    return {
      list: [],
      product: "",
      name: "",
      company: "",
      tel: "",
      email: "",
      remark: "",
      alert: null,
    };
  },
  setup() {
    const route = useRoute();
    const getParams = () => {
      return route.params;
    };
    return {
      getParams,
    };
  },
  name: "Form",
  components: {
    Footer,
  },
  created: function () {
    let language = localStorage.getItem("language");
    GET_PRODUCTS(`page_size=100&language=${language}`).then((res) => {
      let list = [];
      for (let i = 0; i < res.results.length; i++) {
        let temp = res.results[i];
        temp.description = res.results[i].description.replace(
          /\r\n/g,
          "<br />"
        );
        let array = res.results[i].features.split(/\r\n/);
        temp.features = array.filter((item) => item.trim() !== "");
        list.push(temp);
      }
      this.list = list;
    });
  },
  mounted() {
    if (isMobile) {
      const meta = document.querySelector("meta[name='viewport']");
      if (meta) {
        meta.setAttribute("content", `width=1000, user-scalable=no`);
      }
    }
  },
  methods: {
    submit_form() {
      if (this.product === "") {
        this.alert = 1;
        return;
      }
      if (this.name.trim() === "") {
        this.alert = 2;
        return;
      }
      if (this.company.trim() === "") {
        this.alert = 3;
        return;
      }
      const phone = /^1[3|4|5|6|7|8|9]\d{9}$/;
      if (!phone.test(this.tel)) {
        this.alert = 4;
        return;
      }
      const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.email !== "") {
        if (!email.test(this.email)) {
          this.alert = 5;
          return;
        }
      }
      GET_COMMENTS({
        product: this.product,
        name: this.name,
        company: this.company,
        phone: this.tel,
        email: this.email,
        comment: this.remark,
      }).then(() => {
        toaster.success(`提交成功！`);
        let index = this.list.findIndex((obj) => obj.id === this.product);
        let temp = this.list[index];
        let submitInfo = localStorage.getItem("submitInfo")
          ? JSON.parse(localStorage.getItem("submitInfo"))
          : [];
        if (!submitInfo.includes(this.product)) {
          submitInfo.push(this.product);
          localStorage.setItem("submitInfo", JSON.stringify(submitInfo));
        }
        this.downloadFile(temp.file, temp.model);
      });
    },
    downloadFile(data, fileName) {
      // 下载pdf文件
      const a = document.createElement("a");
      // 这里是将url转成blob地址，
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          let url = URL.createObjectURL(blob);
          a.href = url;
          a.setAttribute("download", fileName + ".pdf");
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(a);
          window.history.go(-1);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  height: calc(100vh - 34px);
  min-height: 679px;
  background: #3b4455;
  .content_us_left_area {
    width: 50%;
    height: 679px;
    background: url("../assets/form_bg_left.png") top center repeat-x;
    background-size: auto 100%;
    float: left;
  }
  .content_us_right_area {
    width: 50%;
    height: 679px;
    background: url("../assets/form_bg_right.png") top center repeat-x;
    background-size: auto 100%;
    float: right;
  }
  .page_frame {
    position: relative;
    width: 1000px;
    height: 679px;
    margin: 0 auto;
    background: url("../assets/form_bg.png") center center no-repeat;
    background-size: contain;
    z-index: 2;
    .form_select {
      position: absolute;
      top: 267px;
      left: 100px;
      font-size: 31px;
      color: #ffffff;
      font-weight: bold;
      line-height: 38px;
      text-align: left;
    }
    .qrcode1 {
      position: absolute;
      top: 473px;
      left: 179px;
      width: 70px;
      height: 18px;
      padding-top: 70px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      background: url("../assets/form_QRcode1.png") top center no-repeat;
      background-size: 100% auto;
      z-index: 2;
    }
    .qrcode2 {
      position: absolute;
      top: 473px;
      left: 278px;
      width: 70px;
      height: 18px;
      padding-top: 70px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      background: url("../assets/form_QRcode2.png") top center no-repeat;
      background-size: 100% auto;
      z-index: 2;
    }
    .red_line {
      position: absolute;
      top: 540px;
      right: 0;
      width: 604px;
      height: 1px;
      background: #b00011;
    }
    .content_us_table {
      position: absolute;
      top: 259px;
      right: 12px;
      width: 592px;
      height: 364px;
      text-align: left;
      z-index: 3;
      .input_frame1 {
        width: 592px;
        height: 42px;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 42px;
        padding-left: 15px;
        padding-right: 34px;
        border-radius: 8px;
        margin-bottom: 11px;
        background: url("../assets/contact_select.png") #20242f center right
          17px no-repeat;
        background-size: 6px auto;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .input_frame2 {
        width: 274px;
        height: 42px;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 42px;
        padding-left: 15px;
        border-radius: 8px;
        margin-bottom: 12px;
        background: #20242f;
        display: block;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .input_frame2::-webkit-input-placeholder {
        /*WebKit browsers*/
        color: #b1b3b7;
      }
      .input_frame2::-moz-input-placeholder {
        /*Mozilla Firefox*/
        color: #b1b3b7;
      }
      .input_frame2::-ms-input-placeholder {
        /*Internet Explorer*/
        color: #b1b3b7;
      }
      .input_frame2::placeholder {
        color: #b1b3b7;
      }
      .input_frame3 {
        position: absolute;
        top: 53px;
        right: 0;
        width: 274px;
        height: 204px;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 42px;
        padding-left: 15px;
        border-radius: 8px;
        background: #20242f;
      }
      .input_frame3::-webkit-input-placeholder {
        /*WebKit browsers*/
        color: #b1b3b7;
      }
      .input_frame3::-moz-input-placeholder {
        /*Mozilla Firefox*/
        color: #b1b3b7;
      }
      .input_frame3::-ms-input-placeholder {
        /*Internet Explorer*/
        color: #b1b3b7;
      }
      .input_frame3::placeholder {
        color: #b1b3b7;
      }
      .submit_notes {
        position: absolute;
        bottom: 25px;
        left: 0;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 37px;
      }
      .submit_btn {
        position: absolute;
        bottom: 25px;
        right: 0;
        width: 109px;
        height: 37px;
        border-radius: 10px;
        background: #b10011;
        font-size: 12px;
        color: #ffffff;
        line-height: 37px;
        letter-spacing: 2px;
        text-align: center;
      }
    }
  }
}
</style>

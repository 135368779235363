<template>
  <Header :nav="4" />
  <div class="banner" />
  <div class="c_body">
    <div class="profile">
      <div class="profile_icon" />
      <div class="profile_line1">PRODUCT DETAILS</div>
      <div class="profile_line2">产品详情</div>
    </div>
    <div class="product_title">半导体失效分析设备：</div>
    <div class="product_area" id="product1">
      <img src="../assets/product-1.png" alt />
      <div class="detail">
        <div class="dot" />
        <div class="name">Luxet Thermo 100（制冷锁相红外显微镜）</div>
        <div
          :class="[loading ? 'download_btn disabled' : 'download_btn']"
          @click="download()"
        >
          <span class="loader"></span>
          下载资料
        </div>
        <div class="view_btn" @click="view1()">在线打开</div>
      </div>
      <p class="dec">
        Thermo&nbsp;100是一款全新的锁相红外显微成像系统。配备了全自动运动系统、高灵敏度制冷型中波红外相机、广角镜头与大倍率显微镜头、高压源表，可以适用于晶圆、电路板等多种不同种类器件的失效点定位。
      </p>
    </div>
    <div class="split" />
    <div class="product_area" id="product2">
      <img src="../assets/product-2.png" alt />
      <div class="detail">
        <div class="dot" />
        <div class="name">Luxet Thermo 50（非制冷锁相红外显微镜）</div>
        <div
          :class="[loading1 ? 'download_btn disabled' : 'download_btn']"
          @click="download1()"
        >
          <span class="loader"></span>
          下载资料
        </div>
        <div class="view_btn" @click="view2()">在线打开</div>
      </div>
      <p class="dec">
        Thermo&nbsp;50是一款基于非制冷红外相机的锁相红外显微成像系统。配备了电动垂直运动系统、高帧频长波红外相机、广角镜头与显微镜头、高压源表，可以适用于封装器件、电路板等多种不同种类器件的失效点定位。
      </p>
    </div>
    <div class="split" />
    <div class="product_area" style="margin-bottom: 135px" id="product3">
      <img src="../assets/product-3.png" alt />
      <div class="detail">
        <div class="dot" />
        <div class="name">Luxet InGaAs 100（微光显微镜）</div>
        <div
          :class="[loading2 ? 'download_btn disabled' : 'download_btn']"
          @click="download2()"
        >
          <span class="loader"></span>
          下载资料
        </div>
        <div class="view_btn" @click="view3()">在线打开</div>
      </div>
      <p class="dec">
        InGaAs 100是一款微光显微成像系统。配备了全自动运动系统、深度制冷型InGaAs相机、不同倍率的显微镜头以及锁相测量模式，可以适用于半导体器件的失效点定位。
      </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useRoute } from "vue-router/dist/vue-router";
import VueScrollTo from "vue-scrollto";
import { isMobile } from "@basitcodeenv/vue3-device-detect";

export default {
  name: "Product",
  components: {
    Header,
    Footer,
  },
  setup() {
    const route = useRoute();
    const getQuery = () => {
      return route.query;
    };
    return {
      getQuery,
    };
  },
  data() {
    return {
      loading: false,
      loading1: false,
      loading2: false,
    };
  },
  mounted: function () {
    const { id } = this.getQuery();
    if (id) {
      VueScrollTo.scrollTo(`#product${id}`);
    }
    if (isMobile) {
      const meta = document.querySelector("meta[name='viewport']");
      if (meta) {
        meta.setAttribute("content", `width=1000, user-scalable=no`);
      }
    }
  },
  methods: {
    downloadFile(data, fileName) {
      // 下载pdf文件
      const a = document.createElement("a");
      // 这里是将url转成blob地址，
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          let url = URL.createObjectURL(blob);
          a.href = url;
          a.setAttribute("download", fileName + ".pdf");
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(a);
          this.loading = false;
          this.loading1 = false;
          this.loading2 = false;
        });
    },
    view1() {
      window.open(
        "https://static.campaignchowsangsang.com/others/Luxet_Thermo_100.pdf",
        "_blank"
      );
    },
    view2() {
      window.open(
        "https://static.campaignchowsangsang.com/others/Luxet_Thermo_50.pdf",
        "_blank"
      );
    },
    view3() {
      window.open(
        "https://static.campaignchowsangsang.com/others/Luxet_InGaAs_100.pdf",
        "_blank"
      );
    },
    download() {
      if (!this.loading) {
        this.loading = true;
        this.downloadFile(
          "https://static.campaignchowsangsang.com/others/Luxet_Thermo_100.pdf",
          "Luxet_Thermo_100"
        );
      }
    },
    download1() {
      if (!this.loading1) {
        this.loading1 = true;
        this.downloadFile(
          "https://static.campaignchowsangsang.com/others/Luxet_Thermo_50.pdf",
          "Luxet_Thermo_50"
        );
      }
    },
    download2() {
      if (!this.loading2) {
        this.loading2 = true;
        this.downloadFile(
          "https://static.campaignchowsangsang.com/others/Luxet_InGaAs_100.pdf",
          "Luxet_InGaAs_100"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 260px;
  background: url("../assets/banner4.png") center center no-repeat;
  background-size: cover;
}

.c_body {
  width: 858px;
  margin: 0 auto;
  text-align: left;
  .profile {
    position: relative;
    width: 100%;
    height: 180px;
    border-bottom: 1px solid #aaaaaa;
    .profile_icon {
      position: absolute;
      top: 51px;
      left: 0;
      width: 40px;
      height: 73px;
      background: url("../assets/profile.png") center center no-repeat;
      background-size: contain;
    }
    .profile_line1 {
      position: absolute;
      top: 51px;
      left: 54px;
      font-size: 32px;
      color: #323044;
      line-height: 32px;
    }
    .profile_line2 {
      position: absolute;
      bottom: 56px;
      left: 54px;
      font-size: 24px;
      color: #323044;
      line-height: 24px;
      > span {
        font-size: 18px;
      }
    }
  }
  .product_title {
    font-size: 36px;
    color: #323044;
    font-weight: bold;
    line-height: 36px;
    margin: 45px auto 65px;
  }
  .product_area {
    width: 100%;
    height: auto;
    margin: 0 auto;
    > img {
      width: 100%;
      margin: 0;
      display: block;
    }
    .detail {
      width: 100%;
      height: 42px;
      margin: 36px auto 45px;
      .dot {
        width: 42px;
        height: 42px;
        background-color: #c31920;
        margin-right: 22px;
        float: left;
      }
      .name {
        font-size: 24px;
        color: #323044;
        line-height: 42px;
        float: left;
      }
      .view_btn {
        width: 144px;
        height: 20px;
        font-size: 18px;
        color: #323044;
        line-height: 20px;
        padding: 10px 0;
        margin-right: 10px;
        border-radius: 21px;
        border: 1px solid #323044;
        text-align: center;
        float: right;
        cursor: pointer;
        &:hover {
          color: #c31920;
          border: 1px solid #c31920;
          transform: scale(1.1);
        }
      }
      .download_btn {
        width: 144px;
        height: 20px;
        font-size: 18px;
        color: #323044;
        line-height: 20px;
        padding: 10px 0;
        border-radius: 21px;
        border: 1px solid #323044;
        text-align: center;
        float: right;
        cursor: pointer;
        &:hover {
          color: #c31920;
          border: 1px solid #c31920;
          transform: scale(1.1);
        }
        .loader {
          display: none;
        }
        &.disabled {
          .loader {
            width: 20px;
            height: 20px;
            border: 2px solid #1890ff;
            border-bottom-color: transparent;
            border-radius: 50%;
            vertical-align: top;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
      .download_btn1 {
        width: 144px;
        height: 40px;
        font-size: 18px;
        color: #cccccc;
        line-height: 42px;
        border-radius: 21px;
        border: 1px solid #cccccc;
        text-align: center;
        float: right;
      }
    }
    .dec {
      font-size: 18px;
      color: #323044;
      line-height: 36px;
      text-indent: 2em;
      .red {
        color: #c31920;
      }
    }
  }
  .split {
    width: 100%;
    height: 19px;
    margin: 68px auto 64px;
    background: url("../assets/split.png") center center no-repeat;
    background-size: contain;
  }
}
</style>

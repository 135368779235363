<template>
  <Header :nav="5" />
  <div class="banner" />
  <div class="c_body">
    <div class="profile">
      <div class="profile_icon" />
      <div class="profile_line1">CONTACT</div>
      <div class="profile_line2">联系我们</div>
    </div>
    <div class="line">
      <div class="logo" />
      <div class="mid_line" />
      <div class="address">
        苏州地址：苏州工业园区金鸡湖大道88号人工智能产业园G1-1001<br />
        上海地址：上海市浦东新区碧波路 690 号<br />
        张江微电子港 6 号楼 101-12 室<br />
        邮箱：fiona@luxet.cn<br />
        融资业务：xiangan@luxet.cn
      </div>
      <div class="qrcode3" />
    </div>
    <baidu-map
      class="map"
      :center="{ lng: 121.587103, lat: 31.205968 }"
      :zoom="17"
      :dragging="true"
      @ready="ready"
    >
      <bm-marker
        :position="{ lng: 121.587103, lat: 31.205968 }"
        :dragging="false"
        animation="BMAP_ANIMATION_BOUNCE"
      ></bm-marker>
    </baidu-map>
  </div>
  <div class="submit_area">
    <div class="notes">您可以通过此处快速发送邮件：</div>
    <div class="textarea_wrap">
      <textarea
        v-model="message"
        placeholder=""
        name=""
        maxlength="200"
        class="textarea"
      ></textarea>
      <span class="message-counter">{{ message.length }} / 200</span>
    </div>
    <div class="submit_btn" @click="submit">提交</div>
  </div>
  <Footer />
</template>

<script>
import { useRoute } from "vue-router";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GET_COMMENTS } from "../http/api";
import { createToaster } from "@meforma/vue-toaster";
import { isMobile } from "@basitcodeenv/vue3-device-detect";

const toaster = createToaster({
  position: "top",
  duration: 2500,
});

export default {
  setup() {
    const route = useRoute();
    const getParams = () => {
      return route.params;
    };
    return {
      getParams,
    };
  },
  name: "ContactUs",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    if (isMobile) {
      const meta = document.querySelector("meta[name='viewport']");
      if (meta) {
        meta.setAttribute("content", `width=1000, user-scalable=no`);
      }
    }
  },
  methods: {
    submit() {
      GET_COMMENTS({
        comment: this.message,
      }).then(() => {
        this.message = "";
        toaster.success(`提交成功！`);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 260px;
  background: url("../assets/banner3.png") center center no-repeat;
  background-size: cover;
}

.c_body {
  width: 858px;
  margin: 0 auto;
  text-align: left;
  .profile {
    position: relative;
    width: 100%;
    height: 180px;
    border-bottom: 1px solid #aaaaaa;
    .profile_icon {
      position: absolute;
      top: 51px;
      left: 0;
      width: 40px;
      height: 73px;
      background: url("../assets/profile.png") center center no-repeat;
      background-size: contain;
    }
    .profile_line1 {
      position: absolute;
      top: 51px;
      left: 54px;
      font-size: 32px;
      color: #323044;
      line-height: 32px;
    }
    .profile_line2 {
      position: absolute;
      bottom: 56px;
      left: 54px;
      font-size: 24px;
      color: #323044;
      line-height: 24px;
      > span {
        font-size: 18px;
      }
    }
  }
  .line {
    width: 100%;
    height: 192px;
    margin: 70px auto;
    .logo {
      width: 102px;
      height: 100px;
      margin-left: 23px;
      margin-top: 46px;
      background: url("../assets/logo1.png") center center no-repeat;
      background-size: contain;
      float: left;
    }
    .mid_line {
      width: 1px;
      height: 192px;
      background-color: #000;
      margin: 0 57px;
      float: left;
    }
    .address {
      width: 353px;
      font-size: 18px;
      color: #323044;
      line-height: 32px;
      float: left;
    }
    .qrcode3 {
      width: 94px;
      height: 125px;
      margin-top: 33px;
      margin-right: 15px;
      background: url("../assets/qrcode3.png") center center no-repeat;
      background-size: contain;
      float: right;
    }
  }
  .map {
    width: 758px;
    height: 476px;
    margin: 0 auto 86px;
  }
}
.submit_area {
  width: 100%;
  height: 638px;
  margin-top: -1px;
  padding-top: 1px;
  background: url("../assets/submit_bg.png") center center no-repeat;
  background-size: cover;
  .notes {
    width: 758px;
    font-size: 24px;
    color: #ffffff;
    line-height: 24px;
    margin: 76px auto 44px;
    text-align: left;
  }
  .textarea_wrap {
    position: relative;
    width: 758px;
    height: 275px;
    margin: 0 auto;
    background: url("../assets/textarea.png") center center no-repeat;
    background-size: cover;
    .textarea {
      width: 100%;
      height: 100%;
      padding: 15px 15px 15px 18px;
      font-size: 20px;
      color: #ffffff;
      line-height: 36px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
  }
  .message-counter {
    position: absolute;
    right: 20px;
    bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    line-height: 16px;
  }
  .submit_btn {
    width: 70px;
    height: 70px;
    margin: 80px auto 0;
    border-radius: 35px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 800;
    line-height: 70px;
    text-align: center;
    background-color: #bb1b21;
  }
}
</style>
